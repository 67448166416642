import { useState, useEffect } from "react";

import { TextField, InputAdornment } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import "moment/locale/en-gb";

export default function Picker({
  label,
  value,
  disableDay,
  disablePast,
  onChange,
  variant,
  fullWidth,
  icon,
  sx,
  inputSX,
  required,
}) {
  const currentLang = "it";
  moment.locale(currentLang);

  // callback
  const handleChange = (newValue) => {
    if (onChange) onChange(moment(newValue).format("YYYY-MM-DD"));
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={currentLang}
      sx={{ width: "100%" }}
    >
      <DatePicker
        shouldDisableDate={disableDay}
        label={required ? `${label}*` : label}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={fullWidth}
            inputProps={{
              ...params.inputProps,
              placeholder: "gg/mm/aaaa",
            }}
          />
        )}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              sx: { ...inputSX },
              variant: { variant },
            },
          },
        }}
        onChange={handleChange}
        value={moment(value)}
        inputFormat="DD/MM/YYYY"
        sx={fullWidth ? { ...sx, width: "100%" } : sx}
        disablePast={disablePast}
        OpenPickerButtonProps={{ lang: currentLang }}
      />
    </LocalizationProvider>
  );
}
